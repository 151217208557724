.c-services {
    @media screen and (min-width: $screen-md-min) {
        display: flex;
    }

    &-secondary {
        margin-right: pxrem(60);
        display: block;
        flex-basis: pxrem(350);
        flex-shrink: 0;

        @media (max-width: $screen-sm-max) {
            display: none;
        }

        @media (min-width: $screen-lg-min) {
            margin-right: pxrem(120);
            flex-basis: pxrem(500);
        }
    }

    &-main {
        max-width: pxrem(682);
        padding: 0 7.5%;

        @media screen and (min-width: $screen-md-min) {
            padding: pxrem(60) 0 0;
        }
    }
}
