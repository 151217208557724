.c-link-btn {
    color: $c-black;
    padding: pxrem(5) pxrem(3);
    padding-top: pxrem(10);
    border-bottom: 1px solid fade-out($c-black, 0.8);
    font-size: pxrem(14);
    cursor: pointer;

    &-white {
        color: $c-white;
        border-bottom: 1px solid fade-out($c-white, 0.8);
    }
}
