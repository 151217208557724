.headroom {
    z-index: 1999;
    top: 0;
    right: 0;
    left: 0;

    &--unfixed {
        position: relative;
        transform: translateY(0);
    }

    &--scrolled {
        transition: transform 200ms ease-in-out;
    }

    &--unpinned {
        position: fixed;
        transform: translateY(-100%);
    }

    &--pinned {
        position: fixed;
        transform: translateY(0%);
    }
}
