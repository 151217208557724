@font-face {
    font-family: "DINCond-Black";
    src: url("fonts/DINCond-Black.eot");
    src: url("fonts/DINCond-Black.eot?#iefix") format("embedded-opentype"),
    url("fonts/DINCond-Black.svg#DINCond-Black") format("svg"),
    url("fonts/DINCond-Black.woff") format("woff"),
    url("fonts/DINCond-Black.woff2") format("woff2"),
    url("fonts/DINCond-Black.ttf") format("truetype"),
    url("fonts/DINCond-Black.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-Bold";
    src: url("fonts/OpenSans-Bold.eot");
    src: url("fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/OpenSans-Bold.svg#OpenSans-Bold") format("svg"),
    url("fonts/OpenSans-Bold.woff") format("woff"),
    url("fonts/OpenSans-Bold.woff2") format("woff2"),
    url("fonts/OpenSans-Bold.ttf") format("truetype"),
    url("fonts/OpenSans-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-SemiBold";
    src: url("fonts/OpenSans-SemiBold.eot");
    src: url("fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("fonts/OpenSans-SemiBold.svg#OpenSans-SemiBold") format("svg"),
    url("fonts/OpenSans-SemiBold.woff") format("woff"),
    url("fonts/OpenSans-SemiBold.woff2") format("woff2"),
    url("fonts/OpenSans-SemiBold.ttf") format("truetype"),
    url("fonts/OpenSans-SemiBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "OpenSans-Regular";
    src: url("fonts/OpenSans-Regular.eot");
    src: url("fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/OpenSans-Regular.svg#OpenSans-Regular") format("svg"),
    url("fonts/OpenSans-Regular.woff") format("woff"),
    url("fonts/OpenSans-Regular.woff2") format("woff2"),
    url("fonts/OpenSans-Regular.ttf") format("truetype"),
    url("fonts/OpenSans-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}
