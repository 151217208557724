.c-tag {
    @include font-din;
    margin-bottom: pxrem(10);
    display: inline-block;
    padding: pxrem(8);
    background-color: #000;
    color: #fff;
    font-size: pxrem(12);

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: pxrem(22);
        font-size: pxrem(16);
    }
}
