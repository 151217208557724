.u-img-fill-area {
    background-position: center center;
    background-size: cover;
}

// TEXT

.u-lrg-text {
    @include font-sans;
    font-size: pxrem(11);

    @media screen and (min-width: $screen-md-min) {
        font-size: pxrem(24);
        line-height: 1.57;
    }
}

.u-text-align-right {
    text-align: right;
}

.u-std-text {
    @include font-sans;
    font-size: pxrem(11);
}

// MISC

.u-hover-link {
    @include font-din;
    position: relative;
    display: inline-block;
    padding-bottom: pxrem(4);
    color: #000;
    text-transform: uppercase;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: pxrem(24);
        height: pxrem(2);
        background: #000;
        transition: 0.2s linear;

        @media screen and (min-width: $screen-md-min) {
            right: 0;
        }
    }

    &:hover:after {
        width: 100%;
    }

    &-white {
        color: #fff;

        &:after {
            background: #fff;
        }
    }

    &-right:after {
        right: 0;
        left: auto;

        @media screen and (min-width: $screen-md-min) {
            right: 0;
        }
    }
}

// SPACING

.u-std-pt, .l-page-section {
    padding-top: pxrem(80);

    @media screen and (min-width: $screen-md-min) {
        padding-top: pxrem(160);
    }
}

.u-std-pb, .l-page-section {
    padding-bottom: pxrem(80);

    @media screen and (min-width: $screen-md-min) {
        padding-bottom: pxrem(160);
    }
}

.u-sm-pt {
    padding-top: pxrem(40);

    @media screen and (min-width: $screen-md-min) {
        padding-top: pxrem(120);
    }
}

.u-sm-pb {
    padding-bottom: pxrem(40);

    @media screen and (min-width: $screen-md-min) {
        padding-bottom: pxrem(120);
    }
}

.u-img-spacer {
    margin-bottom: pxrem(40);
    display: inline-block;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: pxrem(80);
    }

    &-small {
        @media screen and (min-width: $screen-md-min) {
            margin-bottom: pxrem(64);
        }
    }
}
