.c-thumbnails {
    display: none;

    @media screen and (min-width: $screen-md-min) {
        margin-top: pxrem(8);
        display: flex;
    }

    .c-thumbnail {
        position: relative;
        display: flex;
        height: pxrem(288);
        flex: 1;
        justify-content: center;
        align-items: center;

        &:after {
            content: "";
            z-index: 4;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            opacity: 0.4;
            transition: 0.2s linear;
        }

        &-title {
            @include font-din;
            z-index: 5;
            position: relative;
            padding-bottom: pxrem(10);
            color: #fff;
            font-size: pxrem(16);
            text-transform: uppercase;

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: pxrem(2);
                background: #fff;
                transition: 0.2s linear;
            }
        }

        &:hover {
            &:after {
                opacity: 1;
            }

            .c-thumbnail-title:after {
                width: 100%;
            }
        }

        &.active {
            background: #fff !important;

            &:after {
                opacity: 0 !important;
            }

            .c-thumbnail-title {
                color: #000;

                &:after {
                    width: 100%;
                    background: #000;
                }
            }
        }
    }
}
