.c-hero {
    overflow: hidden;
    position: relative;
    display: flex;
    height: calc(100vh - #{$nav-height-mobile});
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;

    @media (min-width: $grid-float-breakpoint) {
        height: calc(100vh - #{$nav-height-desktop});
    }

    .back-button {
        color: white;
        top: $grid-gutter-width;
        left: $grid-gutter-width;
        position: absolute;
    }

    &-img {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .l-content-container {
        z-index: 200;
        position: absolute;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    h1 {
        margin: auto;
        text-transform: unset;
        opacity: 1;
        transition: 0.2s linear;
    }

    &.fade-out h1 {
        opacity: 0;
    }

    &.half {
        height: 35vw;
        min-height: 50vh;
        max-height: calc(85vh - #{$nav-height-mobile});

        @media (min-width: $grid-float-breakpoint) {
            max-height: calc(85vh - #{$nav-height-desktop});
        }

        h1 {
            @media (min-width: $screen-md-min) {
                font-size: pxrem(66);
            }
        }
    }

    &.with-video {
        &:before {
            content: "";
            z-index: -200;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
        }

        &:after {
            content: "";
            z-index: -2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
        }
    }

    &-video {
        z-index: -100;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        min-width: 100%;
        height: auto;
        min-height: 100%;
    }

    &-arrow {
        position: absolute;
        bottom: pxrem(75);
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: pxrem(12);
        height: pxrem(12);
        border-right: pxrem(2) solid #fff;
        border-bottom: pxrem(2) solid #fff;
    }
}
