.navbar {
    .nav-search-underlay {
        z-index: 999;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: fade-out(#000, 0.5);
        opacity: 1;
        transition: opacity 150ms;

        &:not(.visible) {
            opacity: 0;
            pointer-events: none;
        }
    }

    .c-nav-overlay {
        overflow-y: auto;
        z-index: 2000;
        position: fixed;
        top: 0px;
        visibility: hidden;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 100vh;
        padding: 0px $grid-gutter-width;
        flex-direction: column;
        background: transparentize($c-black, 0.1);
        opacity: 0;
        transition: 0.2s linear;

        &.is-visible {
            visibility: visible;
            opacity: 1;
        }

        .c-site-header {
            position: relative;
            padding: 0px;
            background-color: transparent;

            @media (min-width: $grid-float-breakpoint) {
                height: $nav-height-desktop;
            }

            img {
                opacity: 0.5;
            }

            .close-button {
                position: relative;
                width: pxrem(30);
                height: pxrem(30);
                padding: 0px;
                border: 0;
                background: none;
                cursor: pointer;

                @media (min-width: $grid-float-breakpoint) {
                    margin-right: $grid-gutter-width;
                }

                &:before, &:after {
                    content: "";
                    position: absolute;
                    top: calc(50% - 1px);
                    left: 20%;
                    width: 60%;
                    height: pxrem(2);
                    background-color: $c-white;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

        &-links {
            display: flex;
            height: calc(100vh - #{$nav-height-mobile});
            flex-grow: 1;

            @media (min-width: $grid-float-breakpoint) {
                height: calc(100vh - #{$nav-height-desktop});
            }

            &-inner {
                max-height: calc(100vh - #{$nav-height-mobile});

                @media (min-width: $grid-float-breakpoint) {
                    max-height: calc(100vh - #{$nav-height-desktop});
                }

                .social-links {
                    padding-bottom: $grid-gutter-width;

                    a {
                        display: inline-block;
                        color: #fff;
                        font-size: pxrem(16);

                        &:not(:first-child) {
                            margin-left: pxrem(16);
                        }
                    }
                }
            }
        }
    }

    .c-site-header {
        $theme-trans-time: 250ms;
        z-index: 1500;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: $nav-height-mobile;
        padding: 0 pxrem(30);
        justify-content: space-between;
        align-items: center;
        background-color: $c-white;
        transition: background-color $theme-trans-time;

        &:after {
            content: "";
            z-index: 1;
            position: absolute;
            opacity: 0;
            transition: opacity 800ms;
        }

        &.modal-link-is-open {
            z-index: 0;

            &:before {
                $close-button-size: 21px;
                content: "";
                z-index: 2;
                position: absolute;
                top: 40px;
                right: $grid-gutter-width;
            }

            &:after {
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                background-color: $c-white;
                opacity: 1;

                @media (min-width: $grid-float-breakpoint) {
                    background-color: $c-black;
                    opacity: 0.81;
                }
            }
        }

        @media (min-width: $grid-float-breakpoint) {
            height: $nav-height-desktop;
        }

        img {
            display: block;
            width: pxrem(82);
        }

        .nav-left {
            display: flex;
            align-items: center;

            .c-site-logo {
                position: relative;

                .light-logo {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    opacity: 0;
                    transition: opacity $theme-trans-time;
               }
            }

            .c-nav-toggle {
                position: relative;
                margin-left: $grid-gutter-width;
                width: pxrem(23);
                height: pxrem(18);
                transition: transform 200ms linear;
                cursor: pointer;

                .toggle-line {
                    position: absolute;
                    left: 0px;
                    margin: 0px;
                    width: 100%;
                    height: pxrem(2);
                    background-color: $c-black;
                    transition: top 150ms, bottom 150ms, transform 150ms, opacity 150ms, background-color $theme-trans-time;

                    &:nth-child(1), &:nth-child(3) {
                        transform: translateY(0%) rotate(0deg);
                    }

                    &:nth-child(1) {
                        top: 0%;
                    }

                    &:nth-child(2) {
                        top: calc(50% - #{pxrem(1)});
                        opacity: 1;
                    }

                    &:nth-child(3) {
                        bottom: 0%;
                    }
                }

                .open .toggle-line {
                    &:nth-child(1) {
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        bottom: 50%;
                        transform: translateY(50%) rotate(-45deg);
                    }
                }
            }
        }

        .nav-right {
            display: flex;
            align-items: center;

            .nav-search, .nav-account, .nav-cart {
                position: relative;
                margin-left: pxrem(15);

                img {
                    display: block;
                    width: 100%;
                }

                .light-icon {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    opacity: 0;
                    transition: opacity $theme-trans-time;
                }
            }

            .nav-search {
                width: pxrem(22);
                padding: 0px;
                border: 0;
                background-color: transparent;
                cursor: pointer;
            }

            .nav-account {
                width: pxrem(22);
            }

            .nav-cart {
                width: pxrem(19);

                &-items {
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    transform: translateX(30%) translateY(30%);
                    width: pxrem(11);
                    height: pxrem(11);
                    border-radius: 50%;
                    background-color: #000;
                    opacity: 0;
                    transition: background-color $theme-trans-time, opacity 150ms;

                    &.active {
                        opacity: 1;
                    }
                }
            }
        }

        &.page-equipment-rentals.top-of-the-page {
            background-color: transparent;

            .nav-left {
                .c-site-logo .light-logo {
                    opacity: 1;
                }

                .c-nav-toggle .toggle-line {
                    background-color: #fff;
                }
            }

            .nav-right {
                .nav-search, .nav-account, .nav-cart {
                    .light-icon {
                        opacity: 1;
                    }
                }

                .nav-cart-items {
                    background-color: #fff;
                }
            }
        }

        &.top-of-the-page[class*="page-wishlists-"] {
            background-color: transparent;
        }

        &-search {
            position: absolute;
            top: 100%;
            left: 0px;
            width: 100%;
            background-color: #000;
            opacity: 1;
            transition: opacity 250ms;

            @media (max-width: $screen-xs-max) {
                top: 0px;
            }

            &:not(.open) {
                opacity: 0;
                pointer-events: none;
            }

            &-inner {
                position: relative;
                margin: 0px auto;
                display: flex;
                max-width: pxrem(1200);
                padding: pxrem(20) pxrem(75);
                justify-content: space-between;
                align-items: center;

                @media (max-width: $screen-xs-max) {
                    height: $nav-height-mobile;
                    padding: pxrem(20) $grid-gutter-width;
                }

                &-icon {
                    position: relative;
                    top: pxrem(1);
                    margin-right: pxrem(15);
                    width: pxrem(21);
                    flex-shrink: 0;
                    border: 0;
                    background-color: transparent;

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                &-input {
                    @include font-din;
                    display: block;
                    max-width: pxrem(1100);
                    flex-grow: 1;
                    border: 0;
                    background-color: transparent;
                    color: #fff;
                    font-size: pxrem(30);
                    opacity: 1;

                    @media (max-width: $screen-xs-max) {
                        font-size: pxrem(20);
                    }
                }

                &-close {
                    position: relative;
                    margin-left: pxrem(15);
                    width: pxrem(30);
                    height: pxrem(30);
                    padding: 0px;
                    flex-shrink: 0;
                    border: 0;
                    background-color: transparent;
                    font-size: 0px;
                    cursor: pointer;

                    &-inner {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        width: pxrem(17);
                        height: pxrem(17);

                        &:before, &:after {
                            content: "";
                            position: absolute;
                            top: calc(50% - #{pxrem(2)});
                            left: 0px;
                            width: 100%;
                            height: pxrem(4);
                            background-color: #fff;
                        }

                        &:before {
                            transform: rotate(45deg);
                        }

                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                }

                &-results {
                    position: absolute;
                    top: 100%;
                    left: ($grid-gutter-width / 2);
                    width: calc(100% - #{$grid-gutter-width});
                    padding: ($grid-gutter-width * 2);
                    background-color: #121212;
                    color: #fff;
                    opacity: 1;
                    transition: opacity 250ms;

                    @media (max-width: $screen-xs-max) {
                        left: 0px;
                        width: 100%;
                        padding: pxrem(25) $grid-gutter-width;
                    }

                    &:not(.visible) {
                        opacity: 0;
                        pointer-events: none;
                    }

                    &-inner {
                        display: flex;

                        &-heading-column {
                            @media (max-width: $screen-sm-max) {
                                display: none;
                            }

                            h1 {
                                @include font-sans-semibold;
                                margin: 0px;
                                font-size: pxrem(18);
                                text-transform: none;
                            }

                            a {
                                position: relative;
                                margin-top: pxrem(15);
                                display: inline-block;
                                padding-bottom: pxrem(4);
                                color: #787878;
                                font-size: pxrem(11);

                                &:after {
                                    content: "";
                                    position: absolute;
                                    bottom: 0px;
                                    left: 0px;
                                    width: 100%;
                                    height: pxrem(1);
                                    background-color: #787878;
                                }
                            }
                        }

                        &-list-column {
                            @media (min-width: $screen-md-min) {
                                padding-left: ($grid-gutter-width * 2);
                            }

                            @media (min-width: $screen-lg-min) {
                                padding-left: ($grid-gutter-width * 3);
                            }

                            @media (min-width: $screen-xl-min) {
                                padding-left: ($grid-gutter-width * 4);
                            }

                            a {
                                @include font-sans;
                                color: #fff;
                                font-size: pxrem(14);

                                @media (max-width: $screen-xs-max) {
                                    font-size: pxrem(12);
                                }
                            }

                            ul {
                                margin: 0px;
                                padding-left: 0px;
                                list-style: none;

                                @media (max-width: $screen-xs-max) and (max-height: 479px) {
                                    column-count: 2;
                                }

                                li {
                                    &:not(:last-child) {
                                        margin-bottom: pxrem(15);
                                    }

                                    a strong {
                                        @include font-sans-bold;
                                    }
                                }
                            }

                            &-more {
                                margin-top: $grid-gutter-width;
                                display: inline-block;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }

    .c-site-nav, .c-secondary-nav {
        li {
            &:not(:last-child) {
                padding-bottom: pxrem(4);
            }
        }

        a {
            color: #fff;
            text-transform: uppercase;
        }
    }

    .c-site-nav {
        position: relative;
        margin-bottom: pxrem(20);
        padding-bottom: pxrem(36);

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: pxrem(24);
            height: pxrem(2);
            background: #fff;
        }

        &-label {
            @include font-din(900);
            display: inline-block;
            color: #fff;
            font-size: pxrem(56);
            text-transform: uppercase;
            cursor: pointer;
            text-align: left;
        }

        &-submenu {
            overflow: hidden;
            max-height: 0;
            padding: 0;
            transition: all 0.2s linear;

            &.is-open {
                max-height: pxrem(700);
                padding: pxrem(16) 0 pxrem(20);
            }

            a {
                @include font-din;
                color: rgba(255, 255, 255, 0.6);
                font-size: pxrem(24);
                line-height: 1.5em;
                transition: color 150ms;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    .c-secondary-nav {
        @include font-din;
        margin-bottom: pxrem(36);
        font-size: pxrem(22);
    }
}
