.checkbox-button {
    $box-size: 20px;
    position: relative;
    margin-right: $grid-gutter-width / 2;
    display: inline-block;
    width: $box-size;
    height: $box-size;
    border: 1px solid $c-black;
    cursor: pointer;

    &-inner {
        position: absolute;
        top: -5px;
        left: -3px;
        transform: rotate(45deg);
        transform-origin: 0% 0% 0;
        width: 200%;
        max-width: 0%;
        height: 2px;
        background-color: $c-black;
        transition: max-width 500ms;
    }

    &.enabled {
        .checkbox-button-inner {
            max-width: 200%;
        }
    }
}
