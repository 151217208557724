.flex-row {
    display: flex;
    flex-wrap: wrap;

    &.justify-center {
        justify-content: center;
    }

    &.align-center {
        align-items: center;
    }

    &.align-end {
        align-items: center;
    }

    &.column-mobile {
        @media (max-width: $grid-float-breakpoint-max) {
            flex-direction: column;
        }
    }

    &.column-reverse-mobile {
        @media (max-width: $grid-float-breakpoint-max) {
            flex-direction: column-reverse;
        }
    }

    &.space-between {
        justify-content: space-between;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;

    &.align-end {
        align-items: flex-end;
    }

    &.c-1-3 {
        @media (min-width: $grid-float-breakpoint-max) {
            width: 33.3%;
        }
    }

    &.c-2-3 {
        @media (min-width: $grid-float-breakpoint-max) {
            width: 66.6%;
        }
    }

    &.c-1-4 {
        @media (min-width: $grid-float-breakpoint-max) {
            width: 25%;
        }
    }

    &.c-3-4 {
        @media (min-width: $grid-float-breakpoint-max) {
            width: 75%;
        }
    }

    &.c-1-2 {
        @media (min-width: $grid-float-breakpoint-max) {
            width: 50%;
        }
    }
}
