.no-scroll {
    overflow: hidden;

    &.modal-link-open {
        .tab-bar {
            z-index: 0;
        }
    }
}

html {
    box-sizing: border-box;
    background-color: #fff;
    font-size: $base-font-size;

    @media (min-width: 1950px) {
        font-size: 18px;
    }

    @media (min-width: 2150px) {
        font-size: 20px;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }
}

body {
    @include font-sans;
    font-size: pxrem(14);
    text-rendering: optimizeLegibility;

    @media screen and (min-width: $screen-md-min) {
        font-size: pxrem(15);
    }

    @media screen and (min-width: 1080px) {
        font-size: pxrem(16);
    }
}

#root {
    display: flex;
    min-height: 100vh;
    padding-top: $nav-height-mobile;
    flex-direction: column;

    @media (min-width: $grid-float-breakpoint) {
        padding-top: $nav-height-desktop;
    }

    .page-content {
        display: flex;
        width: 100%;
        min-height: calc(100vh - #{$nav-height-mobile});
        flex-direction: column;
        flex-grow: 1;

        @media (min-width: $grid-float-breakpoint) {
            min-height: calc(100vh - #{$nav-height-desktop});
        }

        > div {
            width: 100%;
            flex-grow: 1;
        }
    }
}

img {
    max-width: 100%;
    height: auto;
}

strong, b {
    font-weight: 400;
}

i, em {
    font-style: italic;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

textarea {
    height: pxrem(100);
}

input[type=text],
input[type=email],
input[type=phone],
input[type=submit],
{
    border-radius: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

.o-content {
    p:not(:last-child) {
        margin-bottom: pxrem(24);
    }

    a {
        color: #000;
        text-decoration: underline;
    }
}

.error-404 {

}
