@import "../_imports.scss";

.c-site-footer {
    padding-top: pxrem(64);
    padding-bottom: pxrem(80);
    background: #000;
    color: #fff;

    @media screen and (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }

    a {
        color: #fff;
    }

    ::placeholder {
        color: #fff !important;
    }

    form {
        position: relative;
        margin-bottom: pxrem(36);
        padding-bottom: pxrem(124);

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: pxrem(24);
            height: pxrem(4);
            background: #000;
        }

        input[type="text"], input[type="email"], textarea {
            @include font-sans;
            display: block;
            width: 100%;
            padding: pxrem(8) pxrem(0) pxrem(10) pxrem(0);
            outline: none !important;
            border: 0;
            border-bottom: pxrem(2) solid #000;
            background: none;
            font-size: pxrem(12);
            font-weight: 700;

            &.error {
                border-bottom-color: $c-error;
            }

            &::placeholder {
                color: #000;
                opacity: 1;
            }

            &.white {
                border-bottom-color: #fff;
                color: #fff;

                &::placeholder {
                    color: #fff;
                }
            }
        }

        input[type="submit"] {
            cursor: pointer;
        }

        select {
            overflow: hidden;
            visibility: hidden;
            width: 0;
            height: 0;
            padding: 0;
            font-size: 0;
            line-height: 0;
            opacity: 0;
        }
    }

    &-newsletter {
        margin-bottom: pxrem(60);

        @media screen and (min-width: $screen-md-min) {
            margin: 0 pxrem(112);
            width: 27%;
        }

        &-form {
            position: relative;
            margin-top: pxrem(40);
            padding-bottom: 0;

            &:after {
                display: none;
            }

            &-fields {
                @media screen and (min-width: $screen-md-min) {
                    display: flex;
                    justify-content: space-between;
                }

                input[type="submit"] {
                    margin-top: pxrem(24);

                    @media screen and (min-width: $screen-md-min) {
                        margin-top: 0;
                        margin-left: pxrem(19);
                    }
                }
            }
        }

        &-heading {
            margin-bottom: pxrem(8);
            font-family: "DINCond-Black";
            font-size: pxrem(24);
            font-weight: 900;

            @media screen and (min-width: $screen-md-min) {
                font-size: pxrem(32);
            }
        }
    }

    &-contact {
        margin-bottom: pxrem(64);

        @media screen and (min-width: $screen-md-min) {
            margin-bottom: 0;
            width: 30%;
        }
    }

    &-heading {
        font-family: "DINCond-Black";
        font-size: pxrem(33);
        font-weight: 900;
        text-transform: uppercase;

        @media screen and (min-width: $screen-md-min) {
            margin-bottom: pxrem(12);
            font-size: pxrem(57);
        }
    }

    &-address {
        margin-bottom: pxrem(34);
        font-size: pxrem(14);

        a {
            color: #fff;

            &:hover {
                opacity: 0.8;
            }
        }

        &-label {
            margin-bottom: pxrem(10);
            font-family: "DINCond-Black";
            font-size: pxrem(12);
        }
    }

    &-copyright {
        font-family: "OpenSans-Regular";
        font-size: pxrem(9);
    }

    &-social-links {
        margin-bottom: pxrem(34);

        a {
            display: inline-block;
            color: #fff;

            &:not(:first-child) {
                margin-left: pxrem(12);
            }
        }
    }
}
