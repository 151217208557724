h1, h2, h3 {
    @include font-din;
}

h1 {
    position: relative;
    margin: pxrem(0) auto pxrem(16) auto;
    font-size: pxrem(40);
    text-transform: uppercase;
    line-break: 1.2;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: pxrem(24);
        font-size: pxrem(80);
        line-height: 1.1;
    }
}

h2 {
    margin-bottom: pxrem(6);
    font-size: pxrem(24);
    line-break: 1.33;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: pxrem(14);
        font-size: pxrem(56);
        line-height: 1.14;
    }
}

h3 {
    margin-bottom: pxrem(32);
    font-size: pxrem(19);
    line-break: 1;

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: pxrem(56);
        font-size: pxrem(40);
    }
}
