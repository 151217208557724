.c-studio-spacer {
    margin-bottom: pxrem(64);

    @media screen and (min-width: $screen-md-min) {
        margin-bottom: pxrem(120);
    }
}

.c-studio {
    @media screen and (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }

    &-graphic {
        overflow: hidden;
        position: relative;
        display: block;
        height: pxrem(240);

        @media screen and (min-width: $screen-md-min) {
            width: 142%;
            height: auto;
        }

        &-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transition: all 0.4s ease-in;
        }

        &:hover {
            .u-hover-link:after {
                width: 100%;
            }

            .c-studio-graphic-image {
                transform: scale(1.05);
            }
        }

        &-heading {
            @include font-din;
            margin-bottom: pxrem(6);
            font-size: pxrem(24);
            text-transform: uppercase;

            @media screen and (min-width: $screen-md-min) {
                font-size: pxrem(40);
            }
        }

        &-content {
            color: #000;

            @media screen and (min-width: $screen-md-min) {
                width: 55%;
            }

            @media screen and (min-width: 1080px) {
                width: 45%;
            }

            @media screen and (min-width: $screen-lg-min) {
                width: 29%;
                max-width: pxrem(296);
            }

            &-large {
                position: absolute;
                top: 0;
                right: 0;
                box-sizing: border-box;
                padding: pxrem(40) 4%;
                background: #fff;

                @media screen and (max-width: $screen-sm-max) {
                    display: none;
                }
            }

            &-small {
                padding: pxrem(40) 7.5% pxrem(30);

                @media screen and (min-width: $screen-md-min) {
                    display: none;
                }
            }
        }

        &-description {
            margin-bottom: pxrem(4);
        }
    }

    &-details {
        padding-right: 7.5%;
        padding-left: 7.5%;

        @media screen and (min-width: $screen-md-min) {
            padding: pxrem(120) 0 pxrem(120) pxrem(80);
        }

        &-attribute {
            &:not(:last-child) {
                margin-bottom: pxrem(10);

                @media screen and (min-width: $screen-md-min) {
                    margin-bottom: pxrem(24);
                }
            }

            &:last-child {
                position: relative;
                margin-bottom: pxrem(18);
                padding-bottom: pxrem(20);

                @media screen and (min-width: $screen-md-min) {
                    margin-bottom: pxrem(34);
                    padding-bottom: pxrem(38);
                }

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: pxrem(24);
                    height: pxrem(4);
                    background: #000;
                }
            }

            &-value {
                @include font-din;
                margin-bottom: pxrem(6);
                font-size: pxrem(24);

                @media screen and (min-width: $screen-md-min) {
                    font-size: pxrem(40);
                }
            }
        }
    }

    &.c-studio-right {
        .c-studio-graphic-content, .c-studio-details {
            padding-left: 0;
        }

        .c-studio-graphic {
            @media screen and (min-width: $screen-md-min) {
                order: 2;
            }

            &-content {
                @media screen and (min-width: $screen-md-min) {
                    right: auto;
                    left: 0;
                    padding-left: 4%;
                }
            }
        }

        .c-studio-details {
            @media screen and (min-width: $screen-md-min) {
                padding-right: pxrem(80);
                padding-left: 0;
            }
        }
    }
}
