.c-studio-specs {
    position: relative;
    width: pxrem(200);
    transition: 0.2s linear;

    &.is-fixed {
        top: pxrem(150);
        height: calc(100vh - #{pxrem(70)});
    }

    &-inner {
        @media screen and (min-width: $screen-md-min) {
            position: absolute;
            top: 0;
            transition: 0.2s linear;
        }

        p {
            margin-bottom: pxrem(16);
        }
    }

    &.position-bottom {
        position: absolute;
        top: auto;
        bottom: pxrem(0);

        .c-studio-specs-inner {
            @media screen and (min-width: $screen-md-min) {
                top: auto;
                bottom: 0;
            }
        }
    }

    &-container {
        margin-bottom: pxrem(32);

        @media screen and (min-width: $screen-md-min) {
            margin-right: pxrem(64);
            margin-bottom: 0;
            width: pxrem(200);
            flex-shrink: 0;
        }

        &.relative {
            position: relative;
        }
    }
}
