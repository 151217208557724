@import "../_imports.scss";

.c-flash-message {
    padding: pxrem(20);

    background-color: $c-black;
    color: $c-white;

    display: flex;
    position: fixed;
    z-index: 1;
    width: 100%;

    transition: transform ease-in-out 200ms;
    transform: translateY(-100%);

    &.visible {
        transform: translateY(0%);
    }

    .message {
        flex-grow: 1;
    }

    button {
        border: none;
        background: none;
        cursor: pointer;
    }
}
