.l-content-container {
    padding-right: 7.5%;
    padding-left: 7.5%;

    &.l-interior {
        h1, p {
            max-width: 100%;

            @media screen and (min-width: $screen-md-min) {
                margin-left: 0;
                max-width: 72%;
            }
        }
    }

    &.l-slim-container {
        @media screen and (min-width: $screen-md-min) {
            padding-right: 20%;
            padding-left: 20%;
        }
    }
}

.l-inset-container {
    margin-right: auto;
    margin-left: auto;
    max-width: pxrem(1600);
    padding: 0px $grid-gutter-width;
}

.l-anchor-left {
    padding-right: 7.5%;
}

.l-anchor-right {
    padding-left: 7.5%;
}

.l-split-content {
    @media screen and (min-width: $screen-md-min) {
        display: flex;
        justify-content: space-between;
    }

    &.big-bottom-margin {
        margin-bottom: pxrem(80);
    }

    &-align-center {
        @media screen and (min-width: $screen-md-min) {
            align-items: center;
        }
    }

    &-equal-width > div {
        @media screen and (min-width: $screen-md-min) {
            flex: 1;

            &:last-child {
                margin-left: pxrem(120);
            }
        }
    }
}

.l-constrain-content {
    @media screen and (min-width: $screen-md-min) {
        width: 70%;
    }
}

.l-collapsing-container {
    @media (min-width: $screen-md-min) {
        padding-right: 7.5%;
        padding-left: 7.5%;
    }
}
