// Breakpoints
$screen-xl: 1600px;
$screen-xl-min: $screen-xl;
$screen-lg-max: ($screen-xl-min - 1);
$screen-lg: 1300px;
$screen-lg-min: $screen-lg;
$screen-md-max: ($screen-lg-min - 1);
$screen-md: 880px;
$screen-md-min: $screen-md;
$screen-sm-max: ($screen-md-min - 1);
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-xs-max: ($screen-sm-min - 1);
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-mobile-max: ($screen-xs-min - 1);

$grid-float-breakpoint: $screen-md-min;
$grid-float-breakpoint-max: ($screen-md-min - 1);

// Sizes
$grid-gutter-width: pxrem(30);
$sm-gutter-width: pxrem(40);
$md-gutter-width: pxrem(55);
$lg-gutter-width: pxrem(75);

// Colors
$c-error: #f03;
$c-white: #fff;
$c-offwhite: #fafafa;
$c-black: #000;
$c-light-grey: #f2f2f2;
$c-grey: #bbb;
$c-link-grey: #919191;
$c-greyer: #707070;

$nav-height-mobile: pxrem(85);
$nav-height-desktop: pxrem(100);
$tab-bar-height-mobile: pxrem(50);
$tab-bar-height-desktop: pxrem(60);
