@mixin normalize-font {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font-din($font-weight: normal) {
    @include normalize-font;
    font-family: "DINCond-Black";
    font-weight: $font-weight;
}

@mixin font-sans-bold {
    @include normalize-font;
    font-family: "OpenSans-Bold";
}

@mixin font-sans-semibold {
    @include normalize-font;
    font-family: "OpenSans-SemiBold";
}

@mixin font-sans {
    @include normalize-font;
    font-family: "OpenSans-Regular";
}
