ul.c-menu {
    padding: pxrem(10) pxrem(20);
    margin: 0;

    > li, .c-menu-item {
        @include font-sans;
        margin-bottom: pxrem(19);

        list-style: circle;
        list-style-position: outside;

        > a {
            color: black;
        }

        a.active {
            @include font-sans-bold;
        }

        &.link {
            cursor: pointer;
        }
    }
}
