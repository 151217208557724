.c-btn {
    @include font-din;
    display: inline-block;
    padding: pxrem(10) pxrem(24);
    border: pxrem(4) solid #000;
    background: none;
    color: #000;
    font-size: pxrem(16);
    text-align: center;
    text-transform: uppercase;
    text-decoration: none !important;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: pxrem(12);
        margin-bottom: pxrem(16);
    }

    &-white {
        border-color: #fff;
        color: #fff;
    }

    &-mini {
        padding: pxrem(8) pxrem(12);
    }

    &-large {
        font-size: pxrem(22);
    }
}
